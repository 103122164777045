<template>
  <section class="image-gallery">
    <div class="container">
      <div class="row" v-if="typeof images == 'object'">
        <div
          class="col-12 col-md-6 img-grid"
          v-for="image of images.slides"
          :key="image.id"
        >
          <b-link :to="image.link">
            <div
              class="img-holder"
              :style="{ backgroundImage: `url(${image.media.url})` }"
            ></div>
          </b-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImageGallery",
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_gallery"
      );
    },
  },
  data: () => ({}),
};
</script>

